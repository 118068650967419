@import "@angular/material/theming";

// Inclua as funções de tema do Angular Material
@include mat-core();

$primary-palette: mat-palette($mat-blue-grey, 800);
$accent-palette: mat-palette($mat-green, 700);
$warn-palette: mat-palette($mat-red, 700);

$background-palette: (
  background: #ecf0f1,
  card: #ffffff,
  dialog: #ffffff,
  hover: #bdc3c7,
  status-bar: #bdc3c7,
);
$custom-theme: mat-light-theme((color: (primary: $primary-palette,
        accent: $accent-palette,
        warn: $warn-palette,
      ),
      background: $background-palette,
    ));

// Aplique o tema personalizado
@include angular-material-theme($custom-theme);


* {
  font-size: 11px
}

.custom-toolbar {
  color: red
}

.spacer {
  flex: 1 1 auto;
}

mat-card {
  width: 95%;
  height: 600px;
}

@media (max-width: 599px) {

  mat-header-cell,
  mat-cell {
    padding: 8px;
  }

  mat-table {
    display: block;
    overflow-x: auto;
  }
}

@media (min-width: 600px) {
  mat-table {
    width: 100%;
  }
}

/* styles.css */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.mat-dialog-container {
  z-index: 1050 !important;
}